<template>
  <div id="explainer-videos">
    <v-row class="text-center d-flex justify-center">
      <v-col cols="10" class="col-xs-10 col-sm-8 col-md-8 col-lg-7 mb-16">
        <div class="py-8"></div>
        <h3 class="mb-6 display-1 white--text">Trailer</h3>

        <v-card style="background-color: #000000 !important">
          <v-img
            :src="require('@/assets/trailer.jpg')"
            class="white--text align-end"
            gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
          >
            <!-- PLAY BUTTON -->
            <v-btn
              absolute
              color="white"
              class="black--text centered-element pa-0"
              icon
              large
              @click="openVideo"
            >
              <v-icon style="font-size: 60px">mdi-play-circle-outline</v-icon>
            </v-btn>
            <!-- <v-card-title>Coming soon</v-card-title> -->
          </v-img>
        </v-card>

        <div class="py-8"></div>
        <div class="py-8"></div>
        <h3 class="mb-6 display-1 white--text">Watch the film</h3>
        <p class="mt-4 white--text">Dal 14 gennaio 2023 in esclusiva su RaiPlay</p>
        <v-card class="d-flex justify-center mt-8" color="black" flat>
          <a href="https://www.raiplay.it/ricerca.html?q=dear+mama" target="_blank">
            <v-img
              alt="Vuetify Name"
              class="shrink"
              contain
              min-width="20"
              max-width="300"
              :src="require('@/assets/RaiPlay_logo.png')"
            />
          </a>
        </v-card>

        <div class="py-8"></div>
        <div class="py-8"></div>
        <h3 class="mb-6 display-1 white--text">Official selection</h3>
        <v-card class="d-flex justify-center mt-8" color="black" flat>
          <a href="https://www.ridf.it/dear-mama/" target="_blank">
            <v-img
              alt="Vuetify Name"
              class="shrink"
              contain
              min-width="20"
              max-width="300"
              :src="require('@/assets/RIDF_laurels.png')"
            />
          </a>
        </v-card>
        <div class="py-8"></div>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <!-- DIALOG -->
    <VideoPlayerInDialog
      v-model="videoDialogOpen"
      :video="selectedVideo"
      :videotitle="selectedVideoTitle"
      :key="videoToPlayId"
    />
  </div>
</template>

<script>
import { storage } from "@/plugins/firebase.js";

export default {
  name: "ExplainerVideos",

  components: {
    VideoPlayerInDialog: () => import("@/components/misc/VideoPlayerInDialog"),
  },

  data() {
    return {
      videoDialogOpen: false,
      selectedVideo: null,
      selectedVideoTitle: null,
      videoToPlayId: 0,
    };
  },

  methods: {
    async openVideo() {
      this.videoToPlayId = this.videoToPlayId + 1;
      let pathTrailerVideo =
        "gs://dear-mama-prod.appspot.com/trailer/dear_mama_trailer_hd.mp4";
      const urlVideo = await storage
        .refFromURL(pathTrailerVideo)
        .getDownloadURL();
      this.selectedVideo = urlVideo;
      this.videoDialogOpen = true;
    },
  },

  created() {},
};
</script>

<style scoped>
.centered-element {
  height: 60px;
  width: 60px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  top: 50%;
  margin-top: -30px;
}
.centered-placeholder-text {
  height: 30px;
  position: absolute;
  left: 50%;
  margin-left: -45px;
  top: 50%;
  margin-top: -15px;
}
</style>

